import * as types from "../types";

export const defaultHeader = () => {
  return {
    type: types.DEFAULT_HEADER,
  };
}

export const defaultHeaderSucces = () => {
  return {
    type: types.DEFAULT_HEADER_SUCCES,
  };
}

export const hiddenHeader = () => {
  return {
    type: types.HIDDEN_HEADER,
  };
}

export const hiddenHeaderSucces = () => {
  return {
    type: types.HIDDEN_HEADER_SUCCES,
  };
}