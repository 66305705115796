import React from "react";
import { WebPhone } from "../../../components";
import "./SupportCenter.css"

function SupportCenter() {
    const sipconfiguration = {
        username: "CblZ8hwHtUH4",
        password: "gKGhfv0awqgnSspCFH29nIwA",
        realm: "baf5108b.dev.sip.itpvoice.net",
        transportOptions: { server: "wss://sip.ws.itpvoice.net:5066" },
        enableCallWaiting: true,
        onInvite: () => {
            console.log("invite");
        },
        onDisconnect: () => {
            console.log("onDisconnect");
        },
        onUnregistered: () => {
            console.log("onUnregistered");
        },
        maxCalls:2,
    };

    return <>
        <div className="itp-test"  >SupportCenter
            <WebPhone sipconfiguration={sipconfiguration} />
        </div>
    </>;
}

export default SupportCenter;