import { combineReducers } from "redux";
import login from "./login";
import sidebar from "./sidebar";
import header from "./header";
import myExtension from "./myExtension";

const rootReducer = combineReducers({
  login: login,
  sidebar: sidebar,
  header: header,
  myExtension: myExtension,
});

export default rootReducer;
