import React from "react";
import "./Test.css"

function Test() {


    return <>
        <div className="itp-test"  >hola</div>
    </>;
}

export default Test;