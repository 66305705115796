export const capitalizeWords = (string) => {
  const words = string.split(" ");

  const CapitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  const result = CapitalizedWords.join(" ");

  return result;
};
