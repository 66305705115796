import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";

export default function ModalUtility({ modal, successFunction }) {
  return (
    <Modal style={{ marginTop: "10rem" }} isOpen={modal} className="info">
      <ModalBody style={{ textAlign: "center", padding: "2rem" }}>
        <p
          style={{
            fontSize: "18px",
            textAlign: "center",
            paddingBottom: "1rem",
          }}
        >
          {" "}
          A link to reset your password has been sent to your email. Please
          check your inbox or spam folders{" "}
        </p>
        <Button
          style={{ fontSize: "18px", padding: "0.7rem 3rem" }}
          onClick={() => successFunction()}
          color="primary"
        >
          Login
        </Button>
      </ModalBody>{" "}
    </Modal>
  );
}
