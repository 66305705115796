import React, { createContext, useReducer, useContext } from 'react';

// State Context
const MenuStateContext = createContext();
// Dispatch Context
const MenuDispatchContext = createContext();

// The reducer function for our context
function menuReducer(state, action) {
  switch (action.type) {
    case 'SET_DEFAULT_MENU':
      return 'default';
    case 'SET_USER_SETTINGS_MENU':
      return 'userSettings';
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
}

export const MenuProvider = ({ children }) => {
  const [currentMenu, dispatch] = useReducer(menuReducer, 'default');
  // console.log("Current Menu:", currentMenu); // Add this line

  return (
    <MenuStateContext.Provider value={currentMenu}>
      <MenuDispatchContext.Provider value={dispatch}>
        {children}
      </MenuDispatchContext.Provider>
    </MenuStateContext.Provider>
  );
};

export const useMenu = () => {
  const context = useContext(MenuStateContext);
  if (context === undefined) {
    throw new Error('useMenu must be used within a MenuProvider');
  }
  return context;
};

export const useMenuDispatch = () => {
  const context = useContext(MenuDispatchContext);
  if (context === undefined) {
    throw new Error('useMenuDispatch must be used within a MenuProvider');
  }
  return context;
};
