import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import ImageUploading from "react-images-uploading";

const ModalPhotos = ({ modal, setModal, imgMessages, setImgMessages }) => {
  const onChangeOneImage = (e) => {
    setImgMessages(e);
  };

  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader className="modal-header">Add Photos</ModalHeader>
      <ModalBody>
        <ImageUploading
          multiple
          value={imgMessages}
          onChange={(e) => {
            onChangeOneImage(e);
          }}
          maxNumber={10}
          dataURLKey="data_url"
          maxFileSize={3145728}
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <div className="row">
              {imageList.map((image, index) => (
                <div key={index} className={`upload__image-wrapper col-4`}>
                  <div key={index} className="image-item">
                    <img src={image["data_url"]} alt="" width="100" />
                    <div className="image-item__btn-remove">
                      <button
                        className="itp-footer-send-button icon-hover"
                        onClick={() => onImageRemove(index)}
                      >
                        {" "}
                        <HighlightOffIcon />
                      </button>
                    </div>
                    <div className="image-item__btn-edit">
                      <button
                        className="itp-footer-send-button icon-hover"
                        onClick={() => onImageUpdate(index)}
                      >
                        <EditIcon />
                      </button>
                    </div>
                  </div>
                </div>
              ))}

              <div
                className={`upload__image-wrapper col-4 ${
                  isDragging && "upload__image-wrapper-hover"
                }`}
                {...dragProps}
              >
                <button className="btn-primary loading" onClick={onImageUpload}>
                  <AddIcon />
                  <span>Add Image</span>
                </button>
              </div>
            </div>
          )}
        </ImageUploading>
      </ModalBody>
      <ModalFooter>
        <button className="btn-light" onClick={() => setModal(false)}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalPhotos;
