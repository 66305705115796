export default function getQueryString(form) {
  let keys = Object.keys(form);
  let parameter = "";
  keys.forEach((key, i) => {
    if(typeof form[key] === "string" || typeof form[key] === "number" || typeof form[key] === "boolean" ){
      parameter = `${parameter}${i === 0 ? "?" : "&"}${key}=${form[key]}`;
    } else if(typeof form[key] === "object" && !Array.isArray(form[key])) {
      parameter = `${parameter}${i === 0 ? "?" : "&"}${key}=${form[key].value}`;
    } else if(Array.isArray(form[key])){
      let listValues = []
      form[key].forEach((element) => {
        listValues.push(element.value)
      })
      parameter = `${parameter}${i === 0 ? "?" : "&"}${key}=${listValues.join(",")}`;
    }
  });

  return parameter;
}
