import React from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";

export default function verifyToken(token) {
  const json = require("../../../config.json");
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  var url;

  if (token) {
    url = urlBase + "/crm/auth/verify";
    var config = {
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
    };

    return axios.get(url, config);
  } else {
    return <Navigate replace to="/login?redirect=true" />;
  }
}
