import React from "react";

function ToggleImage() {
  return (
    <svg
      id="Component_74_14"
      data-name="Component 74 – 14"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <rect
        id="Rectangle_8479"
        data-name="Rectangle 8479"
        width="20"
        height="20"
        fill="#fff"
        opacity="0"
      />
      <g id="more" transform="translate(2.539 8.314)">
        <path
          id="Path_24212"
          data-name="Path 24212"
          d="M4.663,10a1.663,1.663,0,1,0,1.663,1.663A1.668,1.668,0,0,0,4.663,10Z"
          transform="translate(-3 -10)"
          fill="none"
          stroke="#292d32"
          strokeWidth="1"
        />
        <path
          id="Path_24213"
          data-name="Path 24213"
          d="M18.663,10a1.663,1.663,0,1,0,1.663,1.663A1.668,1.668,0,0,0,18.663,10Z"
          transform="translate(-5.36 -10)"
          fill="none"
          stroke="#292d32"
          strokeWidth="1"
        />
        <path
          id="Path_24214"
          data-name="Path 24214"
          d="M11.663,10a1.663,1.663,0,1,0,1.663,1.663A1.668,1.668,0,0,0,11.663,10Z"
          transform="translate(-4.18 -10)"
          fill="none"
          stroke="#292d32"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}

export default ToggleImage;
