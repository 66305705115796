import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./FullscreenImage.css";

const FullscreenImage = ({
  closeFullscreen,
  image,
  handlePrevImage,
  handleNextImage,
}) => {
  const [imageData, setImageData] = useState(null)

  useEffect(() => {
    if (image) {
        setImageData(image)
    }
  }, [image])

  return ReactDOM.createPortal(
    <>
      <div className="fullscreen-image-overlay">
        <div className="fullscreen-header-container">
          <div className="timestamp">{imageData?.timeStap || ""}</div>
          <span className="close-button" onClick={closeFullscreen}>
            <CloseIcon />
          </span>
        </div>
        <div className="image-container">
          <img src={imageData?.src} alt="" />
        </div>
        <span className="arrow left" onClick={handlePrevImage}>
          <ArrowBackIosIcon />
        </span>
        <span className="arrow right" onClick={handleNextImage}>
          <ArrowForwardIosIcon />
        </span>
      </div>
    </>,
    document.getElementById("menu")
  );
};

export default FullscreenImage;
