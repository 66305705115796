import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { LoadingButton } from "../../../../components";

const ModalConfirm = ({
  modal,
  closeModal,
  loading,
  modalFunction,
  invalidStrategy,
}) => {
  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>{!invalidStrategy ? "Confirm" : ""}</ModalHeader>
      <ModalBody>
        {!invalidStrategy ? "Are You Sure" : invalidStrategy}
      </ModalBody>
      <ModalFooter>
        <button disabled={loading} className="btn-light" onClick={closeModal}>
          {!invalidStrategy ? "No" : "Close"}
        </button>
        {!invalidStrategy && (
          <button
            disabled={loading}
            className="btn-primary loading"
            onClick={modalFunction}
          >
            {loading && <LoadingButton />}
            Yes
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ModalConfirm;
