export const POST_LOGIN_REQUESTED = "POST_LOGIN_REQUESTED";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_FAILED = "POST_LOGIN_FAILED";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const DEFAULT_SIDEBAR = "DEFAULT_SIDEBAR";
export const DEFAULT_SIDEBAR_SUCCES = "DEFAULT_SIDEBAR_SUCCES";
export const HIDDEN_SIDEBAR = "HIDDEN_SIDEBAR";
export const HIDDEN_SIDEBAR_SUCCES = "HIDDEN_SIDEBAR_SUCCES";
export const DEFAULT_HEADER = "DEFAULT_HEADER";
export const DEFAULT_HEADER_SUCCES = "DEFAULT_HEADER_SUCCES";
export const HIDDEN_HEADER = "HIDDEN_HEADER";
export const HIDDEN_HEADER_SUCCES = "HIDDEN_HEADER_SUCCES";
export const GET_MY_EXTENTION = "GET_MY_EXTENTION";
export const GET_MY_EXTENTION_SUCCESS = "GET_MY_EXTENTION_SUCCESS";
export const GET_MY_EXTENTION_FAILED = "GET_MY_EXTENTION_FAILED";
export const UPDATE_MY_EXTENTION = "UPDATE_MY_EXTENTION";
export const UPDATE_MY_EXTENTION_SUCCESS = "UPDATE_MY_EXTENTION_SUCCESS";
export const UPDATE_MY_EXTENTION_FAILED = "UPDATE_MY_EXTENTION_FAILED";
