import React, { useEffect, useState } from "react";
import Messages from "./Messages/Messages";
import MyInbox from "./MyInbox/MyInbox";
import "./Conversations.css";

const Conversations = ({ handleActiveCall }) => {
  const [phoneNumberOrMyInbox, setPhoneNumberOrMyInbox] = useState("");
  const [number, setNumber] = useState("");

  useEffect(() => {
    let parameter = window.location.search;
    let numberORInbox = parameter.split("=")[0].slice(1);
    let phoneNumber = parameter.split("=")[1];

    if (numberORInbox === "number") {
      if(phoneNumber !== number) {
        setNumber(phoneNumber);
      }

      setPhoneNumberOrMyInbox("number");
    } else if (numberORInbox === "myInbox") {
      setPhoneNumberOrMyInbox("myInbox");
    }
  }, [window.location.search]);

  return (
    <div className="itp-test">
      {phoneNumberOrMyInbox === "number" && (
        <>
          <Messages number={number} handleActiveCall={handleActiveCall} />
        </>
      )}

      {phoneNumberOrMyInbox === "myInbox" && (
        <>
          <MyInbox />
        </>
      )}
    </div>
  );
};

export default Conversations;
