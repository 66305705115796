export const businessType = {
  PROFESSIONAL: {
    industryId: "professional-services",
    displayName: "Professional Services",
    description: "Professional services.",
  },
  REAL_ESTATE: {
    industryId: "real-estate",
    displayName: "Real Estate",
    description: "Buying and Selling of residential and commercial properties.",
  },
  HEALTHCARE: {
    industryId: "healthcare-and-lifesciences",
    displayName: "Healthcare and Life Sciences",
    description: "Healthcare services, including hospital, health insurances.",
  },
  HUMAN_RESOURCES: {
    industryId: "human-resources-staffing-recruitment",
    displayName: "HR, Staffing or Recruitment",
    description: "Human resources, staffing, or recruitment.",
  },
  ENERGY: {
    industryId: "energy-and-utilities",
    displayName: "Energy and Utilities",
    description: "Energy and Utilities.",
  },
  ENTERTAINMENT: {
    industryId: "entertainment",
    displayName: "Entertainment",
    description: "Entertainment, including gaming and dating services.",
  },
  RETAIL: {
    industryId: "retail-and-consumer-products",
    displayName: "Retail and Consumer Products",
    description:
      "Consumer products and services, including Retails and eTails.",
  },
  TRANSPORTATION: {
    industryId: "transportation-logistics",
    displayName: "Transportation or Logistics",
    description: "Transportation or logistics services.",
  },
  AGRICULTURE: {
    industryId: "agriculture",
    displayName: "Agriculture",
    description: "Agriculture businesses and services.",
  },
  INSURANCE: {
    industryId: "insurance",
    displayName: "Insurance",
    description: "Insurance products and services",
  },
  POSTAL: {
    industryId: "postal-delivery",
    displayName: "Postal and Delivery",
    description: "Postal and delivery services.",
  },
  EDUCATION: {
    industryId: "education",
    displayName: "Education",
    description:
      "Educational institutions providing education to students in educational settings.",
  },
  HOSPITALITY: {
    industryId: "hospitality",
    displayName: "Hospitality and Travel",
    description: "Travel, hospitality and transportation.",
  },
  FINANCIAL: {
    industryId: "financial-services",
    displayName: "Financial Services",
    description: "Banking and financial institution.",
  },
  POLITICAL: {
    industryId: "political",
    displayName: "Political",
    description: "Political campaigns and services.",
  },
  GAMBLING: {
    industryId: "gambling-and-lottery",
    displayName: "Gambling and Lottery",
    description: "Gambling and Lotteries.",
  },
  LEGAL: {
    industryId: "legal",
    displayName: "Legal",
    description: "Legal services and law firms.",
  },
  CONSTRUCTION: {
    industryId: "construction-and-materials",
    displayName: "Construction, Materials, and Trade Services",
    description: "Construction, materials, and trade services.",
  },
  NGO: {
    industryId: "non-profit-organization",
    displayName: "Non-profit Organization",
    description: "Non-profit organization with valid tax-exempted status.",
  },
  MANUFACTURING: {
    industryId: "manufacturing",
    displayName: "Manufacturing",
    description: "Goods producing industries.",
  },
  GOVERNMENT: {
    industryId: "public-sector",
    displayName: "Government Services and Agencies",
    description: "Local, state or federal agencies, utilities.",
  },
  TECHNOLOGY: {
    industryId: "information-technology-services",
    displayName: "Information Technology Services",
    description: "Information technology and information services.",
  },
  COMMUNICATION: {
    industryId: "mass-media-and-communication",
    displayName: "Media and Communication",
    description: "Communication and mass media.",
  },
};
