import React from "react";
import "./Footer.css";

function Footer({ refFooter }) {
  return (
    <>
      <div className="itp-footer" ref={refFooter}></div>
    </>
  );
}

export default Footer;
