import React, { useState, useEffect } from "react";
import { Row, Col, Card, Container, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { LoadingButton } from "../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import voice360Logo from "../../../assets/images/voice360square.png";
import verifyToken from "./verifyToken";
import ModalUtility from "./ModalUtility";
import changePassword from "./ChangePasswordPetition";

function ChangePassword() {
  const [buttonLogIn, setButtonLogIn] = useState(false);
  const [password, setPassword] = useState("");
  const [confirPassword, setConfirPassword] = useState("");
  const [modal, setModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    const tokenParam = params.get("token");

    if (tokenParam) {
      verifyToken(tokenParam)
        .then(({ data: result }) => {
          console.log(result);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            console.log("1111111111");
            setModal(true);
          }
        });
    }
  }, []);

  const send = () => {
    if (
      password === confirPassword &&
      password !== "" &&
      confirPassword !== ""
    ) {
      const params = new URL(document.location).searchParams;
      const tokenParam = params.get("token");

      if (tokenParam) {
        setButtonLogIn(true);
        changePassword(tokenParam, password)
          .then(({ data: result }) => {
            window.location.href = "/login";
          })
          .catch((error) => {
            console.log(error);
            console.log(error.response.status);
            if (error.response.status === 401) setModal(true);
            setButtonLogIn(true);
          });
      }
    } else {
      swal("Oops", "passwords do not match", "error");
    }
  };

  const redirectUser = () => {
    window.location.href = "/page-forgot-password";
  };

  return (
    <React.Fragment>
      <ModalUtility
        modal={modal}
        setModal={setModal}
        redirectUser={redirectUser}
      />
      <div className="account-pages my-5 pt-sm-5">
        <Container className="CenterBottom">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-secundary">
                  <Row>
                    <Col className="col-12">
                      <div className="text-primary  p-4"></div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="logoITP">
                    <Link to="/login">
                      <div className="avatar-md profile-user-wid">
                        <img src={voice360Logo} alt="" height="70" />
                      </div>
                    </Link>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <span>Please enter your new password below</span>
                  </div>
                  <div className="p-2">
                    <div className="form-group">
                      <label className="">
                        <strong>Password</strong>
                      </label>
                      <div className="input-group transparent-append mb-2">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faLock} />
                        </span>
                        <input
                          type={`${showPassword ? "text" : "password"}`}
                          className="form-control"
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Enter Password"
                        />
                        <div
                          className="input-group-text show-pass"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {" "}
                          {showPassword === true ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </div>
                      </div>
                    </div>

                    <div style={{ marginTop: "10px" }} className="form-group">
                      <label className="">
                        <strong>Confirm Password</strong>
                      </label>
                      <div className="input-group transparent-append mb-2">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faLock} />
                        </span>

                        <input
                          type={`${showPassword ? "text" : "password"}`}
                          className="form-control"
                          onChange={(e) => setConfirPassword(e.target.value)}
                          placeholder="Confirm Password"
                        />
                      </div>
                    </div>
                    <div className="mt-3">
                      <button
                        className="btn btn-primary btn-block waves-effect waves-light"
                        onClick={() => {
                          send();
                        }}
                      >
                        {buttonLogIn && <LoadingButton />}

                        {buttonLogIn ? "  Loading..." : "Send"}
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} ITP.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ChangePassword;
