import React, { useEffect, useState } from "react";

const SwitchesOptions = ({ data, saveDataBranch }) => {
  const [options, setOptions] = useState([]);
  const [form, setForm] = useState({
    option: "",
  });

  const defaultOptions = [
    { name: "On", value: "true" },
    { name: "Off", value: "false" },
  ];

  const onChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const sendMenuOptions = () => {
    const name = defaultOptions.find(
      (option) => option.value === form.option
    ).name;

    saveDataBranch({ id: name, name });
  };

  useEffect(() => {
    if (data) {
      const optionsSelected = data.child.map((element) => element.isKeyObject);

      let availableOptions = [];

      defaultOptions.forEach((item) => {
        if (!optionsSelected.includes(item.name)) availableOptions.push(item);
      });

      setOptions(availableOptions);
    }
  }, [data]);

  return (
    <>
      <div className="itp-callFlow-container-module">
        <label className="itp-label-input__label mt-4">Option</label>
        <select
          onChange={onChangeForm}
          value={form.option}
          name="option"
          className="form-select-itp"
        >
          <option value="" disabled>
            Select option
          </option>
          {options.map((element) => (
            <option key={element.value} value={element.value}>
              {element.name}
            </option>
          ))}
        </select>

        <button
          onClick={sendMenuOptions}
          className="btn-primary mt-4"
          disabled={form.option ? false : true}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default SwitchesOptions;
