import { put, takeLatest } from "redux-saga/effects";
import { defaultHeaderSucces } from "../actions/header";

function* defaultStateHeader() {
  yield put(defaultHeaderSucces());
}

function* defaultHeaderSaga() {
  yield takeLatest("DEFAULT_HEADER", defaultStateHeader);
}

export default defaultHeaderSaga;
