import React from "react";
import { Loading } from "../../../index";
import CardWorkflow from "./CardWorkflow/CardWorkflow";
import "./Render.css";

const Render = ({
  data,
  addFirstAction,
  deleteFunction,
  editModuleWithBranch,
  setSelectedAction,
  selectedAction,
  loading,
}) => {
  const RenderCards = (element, start, index) => {
    if (element?.child?.length > 0) {
      return searchChild(element, start, index);
    } else {
      return (
        <CardWorkflow
          element={element}
          ended={true}
          start={start}
          addFirstAction={addFirstAction}
          deleteFunction={deleteFunction}
          editModuleWithBranch={editModuleWithBranch}
          setSelectedAction={setSelectedAction}
          selectedAction={selectedAction}
        />
      );
    }
  };

  const searchChild = (element, start, index) => {
    return (
      <>
        <div key={index} className="itp-card-flow-search-child">
          {element.child.length > 1 ? (
            <CardWorkflow
              element={element}
              start={start}
              manyChildren={true}
              addFirstAction={addFirstAction}
              index={index}
              deleteFunction={deleteFunction}
              editModuleWithBranch={editModuleWithBranch}
              setSelectedAction={setSelectedAction}
              selectedAction={selectedAction}
            />
          ) : (
            <CardWorkflow
              addFirstAction={addFirstAction}
              element={element}
              start={start}
              deleteFunction={deleteFunction}
              editModuleWithBranch={editModuleWithBranch}
              setSelectedAction={setSelectedAction}
              selectedAction={selectedAction}
            />
          )}

          <div className="d-flex align-items-start">
            {element.child.map((el, i) => {
              if (element.child.length === 1) {
                return RenderCards(el, false, i);
              } else {
                if (i === 0) {
                  el.start = true;
                  return RenderCards(el, false, i);
                } else if (i === element.child.length - 1) {
                  el.finish = true;
                  return RenderCards(el, false, i);
                } else {
                  return RenderCards(el, false, i);
                }
              }
            })}
          </div>
        </div>
      </>
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <div>
        <div className="w-100">
          <div className="CardContainer itp-card-flow__CardContainer overflow-auto">
            {RenderCards(data, true, "start")}
          </div>
        </div>
      </div>
    </>
  );
};

export default Render;
