import * as types from "../types";

export const getMyExtention = () => {
  return {
    type: types.GET_MY_EXTENTION,
  };
};

export const updateMyExtention = (data) => {
  return {
    type: types.UPDATE_MY_EXTENTION,
    data,
  };
};
