import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";

export default function ModalUtility({ modal, redirectUser }) {
  return (
    <Modal style={{ marginTop: "10rem" }} isOpen={modal} className="info">
      <ModalBody style={{ textAlign: "center", padding: "2rem" }}>
        <p
          style={{
            fontSize: "18px",
            textAlign: "center",
            paddingBottom: "1rem",
          }}
        >
          {" "}
          The link you have clicked on has expired. Please click below to resend
          a new email containing a refreshed link.{" "}
        </p>
        <Button
          style={{ fontSize: "18px", padding: "0.7rem 3rem" }}
          onClick={() => redirectUser()}
          color="primary"
        >
          Reset Link
        </Button>
      </ModalBody>{" "}
    </Modal>
  );
}
