import { call, put, takeEvery } from "redux-saga/effects";
import { myExtensionService } from "../../services/myExtension.service";

function* fetchMyExtension(action) {
  try {
    const myExtension = yield call(myExtensionService, action.data);
    yield put({ type: "GET_MY_EXTENTION_SUCCESS", payload: myExtension });
  } catch (e) {
    yield put({ type: "GET_MY_EXTENTION_FAILED" });
  }
}

function* myExtensionSaga() {
  yield takeEvery("GET_MY_EXTENTION", fetchMyExtension);
}

export default myExtensionSaga;
