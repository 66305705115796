import * as types from "../types";

export const defaultSidebar = () => {
  return {
    type: types.DEFAULT_SIDEBAR,
  };
}

export const defaultSidebarSucces = () => {
  return {
    type: types.DEFAULT_SIDEBAR_SUCCES,
  };
}

export const hiddenSidebar = () => {
  return {
    type: types.HIDDEN_SIDEBAR,
  };
}

export const hiddenSidebarSucces = () => {
  return {
    type: types.HIDDEN_SIDEBAR_SUCCES,
  };
}