import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ModalError = ({ modal, closeModal }) => {
  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>Error</ModalHeader>
      <ModalBody>This recording is no longer available</ModalBody>
      <ModalFooter>
        <button className="btn-light" onClick={closeModal}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalError;
