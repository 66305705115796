import React from "react";
import { Spinner } from "reactstrap";

const LoadingButton = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Spinner
        style={{ marginRight: "0.5em" }}
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    </div>
  );
};

export default LoadingButton;
