import React from "react";

const Directory = ({ saveModule }) => {

  const saveDirectory = () => {
    const sendData = {}
    saveModule(sendData, "directory")
  };

  return (
    <>
      <button
        onClick={saveDirectory}
        className="btn-primary mt-4"
      >
        Save
      </button>
    </>
  );
};

export default Directory;
