import { put, takeLatest } from "redux-saga/effects";

function* updateMyExtension(action) {
  try {
    yield put({ type: "UPDATE_MY_EXTENTION_SUCCESS", payload: action.data });
  } catch (e) {
    yield put({ type: "UPDATE_MY_EXTENTION_FAILED" });
  }
}

function* updateMyExtensionSaga() {
  yield takeLatest("UPDATE_MY_EXTENTION", updateMyExtension);
}

export default updateMyExtensionSaga;
