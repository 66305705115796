import React, { useState } from "react";
import { Link } from "react-router-dom";
import ForgetPasswordPetition from "./ForgetPasswordPetition";
import ModalUtility from "./ModalUtility";
import voice360Logo from "../../../assets/images/voice360square.png";
import { Loading } from "../../../components";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);


  const send = () => {
    setLoading(true);
    ForgetPasswordPetition(email)
      .then(({ data: result }) => {
        setModal(true);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const successFunction = () => {
    window.location.href = "/login";
  };

  return (
    <div className="authincation h-100 p-meddle">
      <ModalUtility modal={modal} successFunction={successFunction} />
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/login">
                        <img
                          src={voice360Logo}
                          alt=""
                          style={{ width: "200px" }}
                        />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Forgot Password</h4>
                    <div className="form-group">
                      <label className="">
                        <strong>Email</strong>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="hello@example.com"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        onClick={() => send()}
                      >
                        SUBMIT
                      </button>
                    </div>
                    {loading && <Loading />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
