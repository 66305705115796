import React from "react";
import Actions from "./Graphics/Actions/Actions";
import Render from "./Graphics/Render/Render";
import { GraphContent } from "./Styles/styles";
import "./Flows.css";

const Flows = ({
  menu,
  onMenuTriger,
  addFirstAction,
  data,
  deleteFunction,
  editModuleWithBranch,
  setSelectedAction, 
  selectedAction,
  loading,
}) => {
  // const [data, setData] = useState({
  //   title: "title1",
  //   subTitle: "subtitle1",
  //   cardClick: () => console.log("click card"),
  //   addClick: (element) => addFirstAction(element),
  //   icon: <ChatIcon style={{ transform: "scale(1.5)" }}></ChatIcon>,
  //   child: [
  //     {
  //       title: "title2",
  //       cardClick: () => console.log("click card"),
  //       addClick: (element) => addFirstAction(element),
  //       subTitle: "subtitle2",
  //       icon: <ChatIcon style={{ transform: "scale(1.5)" }}></ChatIcon>,
  //       child: [
  //         {
  //           branchName: "Branch Name 1",
  //           title: "title3",
  //           cardClick: () => console.log("click card"),
  //           addClick: (element) => addFirstAction(element),
  //           subTitle: "subtitle3",
  //           icon: <ChatIcon style={{ transform: "scale(1.5)" }}></ChatIcon>,
  //           child: [],
  //         },
  //         {
  //           branchName: "Branch Name 2",
  //           title: "title4",
  //           cardClick: () => console.log("click card"),
  //           addClick: (element) => addFirstAction(element),
  //           subTitle: "subtitle4",
  //           icon: <ChatIcon style={{ transform: "scale(1.5)" }}></ChatIcon>,
  //           child: [
  //             {
  //               branchName: "Branch Name 3",
  //               title: "title4",
  //               cardClick: () => console.log("click card"),
  //               addClick: (element) => addFirstAction(element),
  //               subTitle: "subtitle4",
  //               icon: <ChatIcon style={{ transform: "scale(1.5)" }}></ChatIcon>,
  //               child: null,
  //               noRender: true,
  //             },
  //             {
  //               branchName: "Branch Name 4",
  //               // title: "title4",
  //               // cardClick: () => console.log("click card"),
  //               // addClick: (element) => addFirstAction(element),
  //               // subTitle: "subtitle4",
  //               // icon: <ChatIcon style={{ transform: "scale(1.5)" }}></ChatIcon>,
  //               noRender: true,
  //             },
  //             {
  //               branchName: "Branch Name 5",
  //               // title: "title4",
  //               // cardClick: () => console.log("click card"),
  //               // addClick: (element) => addFirstAction(element),
  //               // subTitle: "subtitle4",
  //               // icon: <ChatIcon style={{ transform: "scale(1.5)" }}></ChatIcon>,
  //               noRender: true,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // });
  return (
    <div className="itp-flow-container">
      <GraphContent>
        <Render
          data={data}
          addFirstAction={addFirstAction}
          deleteFunction={deleteFunction}
          editModuleWithBranch={editModuleWithBranch}
          setSelectedAction={setSelectedAction}
          selectedAction={selectedAction}
          loading={loading}
        />
        <Actions menu={menu} onMenuTriger={onMenuTriger} />
      </GraphContent>
    </div>
  );
};

export default Flows;
