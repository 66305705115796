import React, { useState } from "react";
import { Card } from "react-bootstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import addFlow from "../../../../../assets/icons/addFlow.svg";
import edtiFlow from "../../../../../assets/icons/editFlow.svg";
import "./cardStyles.css";

export default function CardWorkflow({
  element,
  start,
  ended,
  manyChildren,
  addFirstAction,
  index,
  deleteFunction,
  editModuleWithBranch,
  selectedAction,
  setSelectedAction,
}) {
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  const toggle = (event, element) => {
    event.stopPropagation();
    setOpenDropdownIndex(element === openDropdownIndex ? null : element);
  };

  return (
    <div className="itp-card-flow-container" key={index}>
      <div className="CardContainer itp-card-flow__CardContainer">
        {Object.entries(element).length === 0 ? (
          <div className="itp-card-flow-first-action">
            <p>Add the first Action in this workflow</p>
            <button onClick={() => console.log("Add")} className="btn-primary">
              Add Action
            </button>
          </div>
        ) : (
          <>
            {element.branchName && (
              <div
                // className={`itp-card-flow-branchName-border ${
                //   element.start === true || element.finish === true
                //     ? "w-50"
                //     : "w-100"
                // } ${element.start === true ? "ml-50" : "ml-0"} ${
                //   element.finish === true ? "mr-50" : "mr-0"
                // }`}
                style={{
                  width:
                    element.start === true
                      ? // || element.finish === true
                        "50%"
                      : "100%",
                  height: "0px",
                  border: "1px solid #ccc",
                  marginLeft: element.start === true ? "50%" : "0px",
                  marginRight: element.finish === true ? "100%" : "0px",
                }}
              ></div>
            )}
            {element.branchName && (
              <div className="itp-crad-flow-branchName-border-rect"></div>
            )}
            {element.branchName && (
              <Card className={`itp-card-flow-branchName-card ${element.isDeleted ? "deleted" : ""}`}>
                <p className="m-auto text-center">{element.branchName}</p>
              </Card>
            )}
            {element.branchName && (
              <div className="itp-card-flow-branchName-bottom"></div>
            )}
            {/* {element.branchName && (
              <img
                src={addFlow}
                alt="Circle"
                className="cursor-pointer"
                onClick={() =>
                  element.addClick
                    ? element.addClick(element, false, false)
                    : null
                }
              />
            )} */}

            {!start && !element.noRender && (
              <div className="itp-card-flow-branchName-bottom"></div>
            )}
            {!element.noRender && (
              <Card
                onClick={() =>
                  element.cardClick ? element.cardClick(element) : null
                }
                className={`itp-card-flow-content ${
                  element.isFirst === true ? "first" : ""
                } ${selectedAction === element ? "selected" : ""}`}
              >
                {element.isFirst !== true && (
                  <div className="itp-card-flow__card-content-edit-icon">
                    <Dropdown
                      isOpen={element === openDropdownIndex}
                      toggle={(event) => toggle(event, element)}
                    >
                      <DropdownToggle className="nav-link itp-card-flow-dropdown">
                        <img src={edtiFlow} alt="Edit" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            if (element.addClick) {
                              setSelectedAction(element);
                              element.addClick(element, false, true);
                            }
                          }}
                          className="dropdown-item"
                        >
                          <FontAwesomeIcon icon={faPenToSquare} /> Edit Action
                          Details
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            console.log(element);
                            deleteFunction(element);
                          }}
                          className="dropdown-item"
                        >
                          <FontAwesomeIcon icon={faTrash} /> Delete Action
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}
                <div
                  className={`itp-card-flow__card-content-title ${
                    element.isFirst === true ? "first" : ""
                  }`}
                >
                  {element.title}
                </div>
                {element.subtitle && (
                  <div className="itp-card-flow__card-content-subtitle">
                    {element.subtitle}
                  </div>
                )}
              </Card>
            )}
            {!ended | !manyChildren &&
              !element.noRender &&
              !element.noMoreAction && (
                <div className="itp-card-flow-branchName-bottom"></div>
              )}
            {!manyChildren && !element.noRender && !element.noMoreAction && (
              <img
                src={addFlow}
                alt="Circle"
                className="cursor-pointer add-flow-icon"
                onClick={() =>
                  element.addClick
                    ? element.addClick(
                        element,
                        element.child.length > 0 ? false : true,
                        false
                      )
                    : null
                }
              />
            )}
            {manyChildren && !element.noRender && (
              <div className="itp-card-flow-many-children">
                {/* <FiberManualRecordIcon /> */}
                <img
                  src={addFlow}
                  alt="Circle"
                  className="cursor-pointer add-flow-icon"
                  onClick={() => editModuleWithBranch(element)}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
