import React, { useEffect, useState } from "react";
import Select from "react-select";

const TimeSchedule = ({ saveDataBranch, timeGroups, data }) => {
  const [valueTimeGroup, setValueTimeGroup] = useState(null);
  const [timeGroupsOptions, setTimeGroupsOptions] = useState([]);

  useEffect(() => {
    if (data) {
      const timeGroupsSelectedId = data.child.map(
        (element) => element.isKeyObject
      );

      let options = [];

      if (!timeGroupsSelectedId.includes("_")) {
        options.push({
          label: "All Other Times",
          name: "All Other Times",
          value: "_",
          pk: "_",
          id: "_",
        })
      }

      timeGroups.forEach((item) => {
        if (!timeGroupsSelectedId.includes(item.pk)) options.push(item);
      });

      setTimeGroupsOptions(options);
    }
  }, [data]);

  return (
    <>
      <div className="itp-callFlow-container-module">
        <label className="itp-label-input__label mb-2">Time group:</label>
        <Select
          onChange={(e) => setValueTimeGroup(e)}
          className="basic-single"
          classNamePrefix="select"
          value={valueTimeGroup}
          isSearchable={true}
          name="queue"
          placeholder="Select Time Group"
          options={timeGroupsOptions}
        />
        <button
          onClick={() =>
            saveDataBranch({ id: valueTimeGroup.pk, name: valueTimeGroup.name })
          }
          className="btn-primary mt-4"
          disabled={valueTimeGroup ? false : true}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default TimeSchedule;
