import React from 'react';
import searchIcon from "../../assets/images/searchIcon.svg"
import './Search.css';

const Search = ({placeholder}) => {
  return (
    <div className="itp-input-container">
      <img src={searchIcon} alt="Search Icon" className="itp-search-icon" />
      <input type="text" className="itp-input" placeholder={placeholder} />
    </div>
  );
};

export default Search;