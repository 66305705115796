import { put, takeLatest } from "redux-saga/effects";
import { logout } from "../../services/login.service";
import { logoutSuccess } from "../actions/login";

function* logoutFunction() {
  logout();
  yield put(logoutSuccess());
}

function* logoutSaga() {
  yield takeLatest("LOGOUT_REQUEST", logoutFunction);
}

export default logoutSaga;
