import { put, takeLatest } from "redux-saga/effects";
import { hiddenHeaderSucces } from "../actions/header";

function* hiddentSidebarMenu() {
  yield put(hiddenHeaderSucces());
}

function* hiddenHeaderSaga() {
  yield takeLatest("HIDDEN_HEADER", hiddentSidebarMenu);
}

export default hiddenHeaderSaga;
